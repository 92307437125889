// @import '../node_modules/@syncfusion/ej2-material-theme/styles/material.css';
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import './projects/ng-wizard/src/themes/arrows';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/mdc-light-deeppurple/theme.css";



// :root {
//   --primary-color: #2d4099;
//   --secondary-color: #eff3fc;
// }



// @import '../node_modules/angular-archwizard/styles/archwizard.css';
/* You can add global styles to this file, and also import other style files */
.text-primarycolor {
  color:var(--primary-color) !important;
}
.btn-primarcolor  {
  color: #fff !important;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.bg-primarcolor {
  background-color: var(--primary-color) !important;
}
.btn-link:not(.collapsed) {
  width: 100%;
  text-align: center;
  color: var(--primary-color) !important;
  background: var(--secondary-color) !important;
}
// .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
//   color: #fff !important;
//   background-color: var(--primary-color) !important;
// }
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
.btn-primarcolor , .page-item.active .page-link{
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.btn-primarcolor :hover{background-color: var(--primary-color) !important; }
.table > :not(:first-child){border-top:0px solid #ccc !important}
.form-control{background: var(--secondary-color)  !important; border:1px solid var(--secondary-color) !important;color:var(--primary-color)  !important}
.form-control:focus{background: #daeafa !important;}
.ng-select.ng-select-single .ng-select-container{background: var(--secondary-color)  !important; border:1px solid #e3effc !important}
.ng-select.ng-select-multiple .ng-select-container, .ng-select.ng-select-opened>.ng-select-container{background: var(--secondary-color)  !important; border:1px solid #e3effc !important}
.modal-header{background: var(--primary-color) !important;}
.modal-title{color:#fff !important}



.text-icm{color:var(--primary-color)}
.font-26{font-size:24px !important}
.font600{font-weight: 600;}
.font700{font-weight:700;}

// .aw-text{color:#ad208d}
// .ap-text{color:#622f91}
// .re-text{color:var(--primary-color)}
// .en-text{color:#5286c5}
// .ma-text{color:#69af87}

// .aw-bg{background:#ad208d ;}
// .ap-bg{background:#622f91 ;}
// .re-bg{background:var(--primary-color) ;}
// .en-bg{background:#5286c5 ;}
// .ma-bg{background:#69af87 ;}

// .aw-border{border: 1px solid #ad208d}
// .ap-border{border: 1px solid #622f91}
// .re-border{border: 1px solid var(--primary-color)}
// .en-border{border: 1px solid #5286c5}
// .ma-border{border: 1px solid #69af87}

.aw-text{color:var(--primary-color)}
.ap-text{color:var(--primary-color)}
.re-text{color:var(--primary-color)}
.en-text{color:var(--primary-color)}
.ma-text{color:var(--primary-color)}

.aw-bg{background:var(--primary-color) ;}
.ap-bg{background:var(--primary-color) ;}
.re-bg{background:var(--primary-color) ;}
.en-bg{background:var(--primary-color) ;}
.ma-bg{background:var(--primary-color) ;}

.aw-border{border: 1px solid var(--primary-color)}
.ap-border{border: 1px solid var(--primary-color)}
.re-border{border: 1px solid var(--primary-color)}
.en-border{border: 1px solid var(--primary-color)}
.ma-border{border: 1px solid var(--primary-color)}

.text-gray{color:#8f8f8f}
.text-gray-200{color:#b0b2b3}


.accordion .card .card-body::-webkit-scrollbar {
  width: 5px !important;
}
 
.accordion .card .card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}
 
.accordion .card .card-body::-webkit-scrollbar-thumb {
  background-color: darkgrey !important;
  outline: 1px solid slategrey !important;
}


.zoom{transition: 0.5s all ease-in-out;}
.zoom:hover {
  transform: scale(1.02); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.05)
}

.hoverMTop:hover a i{transform: scale(1.2);transition: 0.5s all ease-in-out !important; }

.score-box:hover .shake {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  
  30%, 50%, 70% {
    transform: translate3d(-2px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(2px, 0, 0);
}
}


.hoverMTop:after{
  position:absolute;
  bottom:100%;
  left:0;
  width:0;
  top:-1px;
  height:2px;
  background:var(--primary-color);
  display:block;
  content:'';
  transition: width 0.5s ease-in-out;
}
.hoverMTop:hover:after{
  width:100%;
}

.vertical-collpsed .vertical-menu {
  position: fixed !important;
}

.tooltip-inner {
  background-color: #f5f5f5 !important;
  color: #000 !important;
  font-weight: 600 !important;
  border: 1px dashed #bdc8d9 !important;;
}


[dir=rtl] .main-content
{
    margin-right: 208px;
    margin-left: inherit !important;
}

[dir=rtl]  .footer {
  left: 0 !important;
  right: 205px !important;
}



[dir=rtl] .metismenu {
  padding-right: 0 !important;
}

[dir=rtl] .detail-badge {
  left: 0%;
  right: 10%;
}

[dir=rtl] .borderRightICM {
  border-left:1px dashed #bcbdc8;
  border-right: inherit;
}
[dir=rtl] .text-end {
  text-align: left !important;
}

[dir=rtl] .btn-close {
  margin:0 ! important;
}

//DARK MODE CSS

:root {
  --dark: #000;
  --light: #ffffff;
  --primary:var(--primary-color);
  --primaryLight:var(--secondary-color);
}

body.dark-mode #page-topbar {
  background-color: var(--dark);
}

body.dark-mode .fa, body.dark-mode .fas {
  color: var(--light);
}

body.dark-mode .text-black {
  color:var(--light) !important;
}

body.dark-mode .dropdown-toggle i {
  color: var(--light) !important;
}
body.dark-mode .header-item a span {
  color:var(--dark) !important;
}
body.dark-mode .vertical-menu {
  background: var(--dark) !important;
}

body.dark-mode #sidebar-menu ul li a i {
  color: var(--primary) !important;
}
body.dark-mode #sidebar-menu ul li a {
  color: var(--light) !important;
}
body.dark-mode #sidebar-menu ul li a.active {
  color: var(--dark) !important;
}
body.dark-mode #sidebar-menu ul li a:hover {
  color: var(--dark) !important;
  background: var(--primaryLight);
}
body.dark-mode .logo {
  background-color:var(--dark);
}
body.dark-mode .page-content {
  background-color: #222222;
}
body.dark-mode .footer {  
  color: var(--light);
  background-color: var(--dark);
}

body.dark-mode .h1, body.dark-mode .h2, body.dark-mode .h3, body.dark-mode .h4, body.dark-mode .h5, body.dark-mode .h6, body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4,  body.dark-mode h5, body.dark-mode h6 {
  color: var(--light);
}
body.dark-mode .card {
  background-color: var(--dark);
  background-clip: border-box;
}
body.dark-mode .mini-stats-wid div div div h5, body.dark-mode .blog-stats-wid div div div h5 {
  color: var(--dark) !important;
}
body.dark-mode .demo-video-description {
  color: var(--light);
}
body.dark-mode .logo-dark {
  display: block !important;
}
body.dark-mode .logo-light {
  display: none !important;
}
body.dark-mode .table-bordered td {
  color: var(--light);
}
body.dark-mode .nav-pills .nav-link.active {
  color: var(--primary-color) !important;
  background-color:var(--dark) !important;
  border-right:inherit !important;
}
body.dark-mode .nav-tabs-custom .nav-item .nav-link {
  background: #202020;
  color: var(--light);
}
.nav-item a span i {
  color: var(--dark) !important;
}
body.dark-mode .nav-item a span i {
  color:var(--light) !important;
}
.collapse table tbody tr:nth-of-type(odd){background: #fff !important;}
body.dark-mode .collapse table tbody tr:nth-of-type(odd) {
  background:var(--dark) !important; 
}
body.dark-mode .collapse table tbody tr:nth-of-type(even) {
  background:var(--dark) !important; 
}
 
body.dark-mode #ohbi_driver_table_all thead tr th  {
  color:var(--light);
}
.score-box h1{font-size:50px;font-weight:600;color:#313352;padding:30px 4px}
body.dark-mode .score-box h1 {
  color: var(--light) !important;
}
.score-box {
  border-radius: 8px;
  background: var(--light);
  height: 100%;
  box-shadow: 0 0 10px #ccc !important;
}
body.dark-mode .score-box {
  box-shadow: 0 0 10px #585858 !important;
}
.summary-box {
  border-radius: 8px;
  background: var(--light);
  height: 100%;
  box-shadow: 0 0 10px #ccc;
}
body.dark-mode .summary-box {
  box-shadow: 0 0 10px #585858;
}
body.dark-mode .nav-tabs-custom li a.active {
  background-color: var(--dark);
}
body.dark-mode .nav-tabs-custom .nav-item .nav-link.active {
  background: var(--dark) !important;
}
body.dark-mode #regionAccordion table tr {
  background-color: var(--dark) !important;
}
body.dark-mode #regionAccordion .collapse table td {
  color: var(--light) !important;
}
body.dark-mode #regionScroreOHBI thead tr td {
  color: var(--light) !important;
}
body.dark-mode #oc-custom-tab .nav-link.active {
  color: var(--primary-color) !important;
  background-color:var(--dark) !important;
  border-right:inherit !important;
}
body.dark-mode #oc-custom-tab .nav-link {
  background: #202020;
  color: var(--light);
}

.survey-btn{margin-right: 10px;min-width: 100px;margin-bottom:10px}
table.dataTable tbody td{vertical-align: middle;}

// border: 1px solid var(--primary-color);
//     border-radius: 0px;
//     background-color: #222223;
//     color: #fff;




.menu-box{background: rgb(228, 231, 239);text-align: center;padding:10px 6px;border-radius: 6px;transition: all 1s ease-out;cursor: pointer;}
.menu-box h4{font-size: 12px !important;margin-bottom: 0px;font-weight:600}
.menu-box h1{margin-bottom:0px}
.menu-box i{color: rgb(117, 157, 154);}
.menu-box i.spin{transition: transform 2s ease-in-out;}
.menu-box:hover i.spin{transform: rotate(360deg);}

.menu-box i.rotate{transition: transform 2s ease-in-out;}
.menu-box:hover i.rotate{transform: rotateY(180deg);}


.menu-box.box1{background:var(--secondary-color) ;border:1px solid #d3d8f2}
.menu-box.box2{background:#d6f0f7;border:1px solid rgb(174, 214, 214)}
.menu-box.box3{background:#dddef4;border:1px solid #b8badb}
.menu-box.box4{background:#f6f1de;border:1px solid #e2d9ba}
.menu-box.box5{background:#f4e5dc;border:1px solid #e7d0c2}
.menu-box.box6{background:#f6e3f8;border:1px solid #e0c2e4}
.menu-box.box7{background:#f6dde7;border:1px solid #dfbbca}


// .menu-box.box1 i{color:var(--primary-color)}
.menu-box.box1 i{color:#b5bee9}
.menu-box.box2 i{color:#72b2b2}
.menu-box.box3 i{color:#797cb3}
.menu-box.box4 i{color:#c0b076}
.menu-box.box5 i{color:#bd8867}
.menu-box.box6 i{color:#9f74a3}
.menu-box.box7 i{color:#b96c8b}
.menu-box:hover{box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;}



// .menu-box.box1.active{border:0px solid rgb(174, 214, 214);background: var(--primary-color)}
.menu-box.box1.active{border:0px solid var(--secondary-color);background: var(--primary-color)}
.menu-box.box2.active{border:0px solid rgb(174, 214, 214);background-color: rgb(97, 145, 145) ;}
.menu-box.box3.active{border:0px solid rgb(174, 214, 214);background:#6a6c9e ;}
.menu-box.box4.active{border:0px solid rgb(174, 214, 214);background:#c0b076;}
.menu-box.box5.active{border:0px solid rgb(174, 214, 214);background:#bd8867;}
.menu-box.box6.active{border:0px solid rgb(174, 214, 214);background:#9f74a3;}
.menu-box.box7.active{border:0px solid rgb(174, 214, 214);background:#b96c8b;}

.menu-box.box1.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid var(--primary-color);
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box2.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid rgb(97, 145, 145);
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box3.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid #6a6c9e;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box4.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid #c0b076;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box5.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid #bd8867;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box6.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid #9f74a3;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }

  .menu-box.box7.active:after {
    content: " ";
    position: absolute;
    right: 44%;
    bottom: -10px;
    border-top: 12px solid #b96c8b;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }


  .menu-box.active i{color:#fff}
  .menu-box.active h4{color:#fff !important}
  .menu-box{cursor: pointer !important;}

  .progress {background: #e8e6e6 !important;}
  .card{background: #fff !important;}

  .filterType {
    margin-right: 5;
    margin-left: 5;
  }



  .premium-account:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: rgba(255, 255, 255, .9);    
}
.body-step3 {
  display: flex;
  // justify-content: center; 
  background-color: #fff; 
  margin-top: 20px;
  overflow: hidden; 
  margin-bottom: 30px;
}

// .sortable-list {
//   background: #ffffff;
//   width:80%;  
//   border-radius: 6px;
//   padding: 20px 20px 20px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
// }
.sortable-list {
  background: #ffffff;
  width:80%;  
  border-radius: 6px;
  padding: 20px 20px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  list-style: none;
}

.sortable-list .item {
  margin:15px;
  height: fit-content;
  list-style: none;
  display: flex;
  border: 1px solid #ccc; 
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  transition: background-color 0.2s;
  transition: transform 0.2s;
}

.sortable-list .item:hover {  
 box-shadow: 0 0.25rem 1.5rem #DBDFEA;
 border: 1px dashed var(--primary-color);
  //  transform: scale(1.02);

}
.sortable-list .item:hover{
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  border: 1px dashed var(--primary-color);
  // transform: scale(1.02);
}
// .cardquestions{
//   box-shadow: 0 0.25rem 1.5rem #DBDFEA;
// }
.item .details .img {
  width:30px;
  height: 30px;
  margin-right: 15px;
  background-color: #5286c5;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  // font-weight: bold;
  color: #ffffff;
}

.item .details {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.item .details span {
  font-size: 1.2rem;
  color: #333333; 
}

.item i {
  color: #777777;
  font-size: 1.2rem;
  cursor: pointer; 
}

.item .dragging .details,
.item .dragging i {
  opacity: 0.5;
}
.item.dragging :where(.details,i){ 
  background-color: 'red';  
}
.cardquestions{
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

  // box-shadow: 0 0.25rem 1.5rem #DBDFEA;
  width: 250px;
  height: 100px;
  text-align: center;
  transition: transform 0.2s;
}

.cardquestions:hover{
  box-shadow: 0 0.25rem 1.5rem #DBDFEA;
}
.rowcard{
  display: flex;
  flex-direction: column;  
  margin-top:10px;
  flex-wrap: wrap;
 
}
.cardtitle{
  color:var(--primary-color)
}
.cardbtn{
  position: absolute;
  left: 62%;
}
.paddingcard{
  padding:10px;
  justify-content: space-evenly;
}
.centersurvey{
  text-align: center;
}
.surveybtn{ 
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    bottom: -6%;
    right: 0;
}
.widthbtn{
  width:200px;
  margin:10px;
}
.fs-form li.fs-current {
  visibility: visible
}
.fs-fields>li label.fs-field-label {
  display: inline-block;
  padding: 0 5px 15px 0;
  font-weight: 700;
  pointer-events: none;
  color: #67bd6c
}
.fs-form .fs-display-next .fs-hide .fs-anim-lower,.fs-form .fs-display-next .fs-hide .fs-anim-upper {
  -webkit-animation: animHideNext .5s cubic-bezier(.7,0,.3,1) forwards;
  animation: animHideNext .5s cubic-bezier(.7,0,.3,1) forwards
}
.fs-form .fs-display-next .fs-show .fs-anim-lower,.fs-form .fs-display-next .fs-show .fs-anim-upper {
  -webkit-animation: animShowNext .5s cubic-bezier(.7,0,.3,1) .15s both;
  animation: animShowNext .5s cubic-bezier(.7,0,.3,1) .15s both
}
.fs-radio-group {
  display: flex;
  flex-direction: row;
}
.fs-fields .fs-radio-custom input[type=radio] {
  position: absolute;
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer
}
.fs-fields>li .fs-radio-custom span {
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
.fs-fields>li .fs-radio-custom span label {
  color: rgba(0,0,0,.4);
  transition: color .3s
}
.smileyaddition_style{  
  max-height: 90px;
  max-width: 90px;
  background-color: transparent;
  border: none;
  min-width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left:10px;

}
.smiley_width {
  width: 100%!important;
  padding:0 20px 0 20px;
}
.ss-smiley-icon--5 {
  color: #069858
}

.ss-smiley-icon--4 {
  color: #84b671
}

.ss-smiley-icon--3 {
  color: #b2a24b
}

.ss-smiley-icon--2 {
  color: #d9864c
}

.ss-smiley-icon--1 {
  color: #ec6772
}
.fs-fields>li .fs-radio-custom span {
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
#list {
  display: flex;
  flex-direction: column
}

.action input:checked+span {
  background-color: #8080808c!important
}

.action input[type=radio]:checked+span {
  background-color: #8080808c
}
.fs-fields>li .fs-radio-custom label {
  display: block;
  padding-top: 100%;
  width: 100%;
  height: 100%;
  cursor: pointer;
  margin-top: 10px
}
.fs-fields>li label {
  position: relative
}
.fs-fields>li .fs-radio-custom span {
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
.option_final {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  text-align: left !important;
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
.alpha_span {
  font-size: 100%!important;
  border: none!important;
  background-color: #3f3f3f!important;
  color: #fff!important;
  border-radius: 28px!important;
  width: 28px!important;
  height: 28px!important;
  padding: 3.5px!important;
  font-weight: 600;
  min-width: 28px!important;
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
.js .fs-form-full .fs-fields>li {
  position: absolute;
  top: -30px;
  width: 100%;
  font-size: 30px;
  text-align: left;
  text-align: initial;
  display: flex;
  flex-direction: column;
  align-items: flex-start
}
// .fs-fields [required] {
//   background-image: url(abacusstar.ac45270c590c82354044.svg);
//   background-position: 100% 0;
//   background-size: 18px;
//   background-repeat: no-repeat
// }
.fs-fields input:focus {
  background-color: #3b3f45
}

.fs-fields input {
    display: block;
    margin: 0;
    padding: 0 0 .15em;
    width: 100%;
    border: none;
    border-bottom: 2px solid rgba(0,0,0,.2);
    background: none;
    color: #004a9b;
    text-overflow: ellipsis;
    font-weight: 700;
    font-size: .7em;
    border-radius: 0
}
.inputfield_box{
  position: relative;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  border: 1px solid grey;
  background-color: #ebebeb;
  color: #727272;
  border-radius: 10px;
  min-width: 18em
}
#list_checkbox{
  width:60% !important;

}
.fs-anim-lower{
  width: 30% !important;
  display: block;
  margin: 0;
  padding: 0 0 0.15em;
  width: 100%;
  border: none;
  // border-bottom: 2px solid rgba(0,0,0,.2);
  background: none;
  color: #004a9b;
  text-overflow: ellipsis;
  font-weight: 700;
  font-size: .7em;
  border-radius: 0;
}
.fs-anim-lower .action label{
  width:70% !important;
}
.modal-body ol li{
  padding:30px;
  font-size: 25px;
}
#language-buttons {
  text-align: right;
}

#arabic-translate {
  display: none;
  
}
.english-translate .arabic-translate{
  border:none;
  font-size: 15px;
  cursor:pointer;
  background: none;
  color:#222222;
}
.modal-body .previewmodal ol{
  list-style: none;
}
.card-shadow{
  box-shadow: 0 0 10px #ccc !important;
}
.option{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center !important;
}
// .fc .fc-toolbar-title {
//     font-size: 1.05em !important;
//     margin: 0;
// }
// ::ng-deep .fc-daygrid-day-events .fc-daygrid-event-harness:hover {
 
  //   overflow: hidden;
  //   font-weight: 700;
  //   background: transparent;
  //   position: absolute;
  //   z-index: 9999;
  //   box-shadow: 0 2px 6px rgba(0,0,0,.15);
  
  //   top: 0;
  //   left: 0 /* rtl:ignore */;
  
    // max-width: 276px;
    // font-family: "Poppins", sans-serif;
    // font-family: var(--bs-font-sans-serif);
    // font-style: normal;
    // font-weight: 400;
    // line-height: 1.5;
    // text-align: left;
    // text-align: start;
    // text-decoration: none;
    // text-shadow: none;
    // text-transform: none;
    // letter-spacing: normal;
    // word-break: normal;
    // word-spacing: normal;
    // white-space: normal;
    // line-break: auto;
    // font-size: 0.7109375rem;
    // word-wrap: break-word;
    // background-color: #fff;
    // background-clip: padding-box;
    // border: 1px solid #f6f6f6;
    // border-radius: 0.4rem;
  // width:150% !important;
// }
// .fc .fc-popover {
//   position:absolute;
//   z-index: 9999;
//   box-shadow: 0 2px 6px rgba(0,0,0,.15);
// }
// .fc .fc-daygrid-more-link{
//   cursor: pointer;
//   z-index: 2;
//   position: relative;
// }
// .fc-theme-bootstrap a:not([href]) {
//   color: inherit;
// }
// .fc-direction-ltr .fc-daygrid-event .fc-event-time {
//   display: none;
// }
// .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
//   margin-right: 2px;
//   width: 100% ;
//   overflow: hidden;
//     font-weight: 700;
//     background: transparent;
//     position: absolute;
//     z-index: 9999;
//     box-shadow: 0 2px 6px rgba(0,0,0,.15);
//     transition: width 0.3s;
// }
// .fc-direction-ltr .fc-daygrid-event.fc-event-end:hover,
// .fc-direction-rtl .fc-daygrid-event.fc-event-start:hover {
//   width: auto;
// }
//   fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start :hover{
//     margin-right: 2px;
//     width: 125% !important;
//     overflow: hidden;
//     font-weight: 700;
//     background: transparent;
//     position: absolute;
//     z-index: 9999;
//     box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
//     max-width: 276px;
//     font-family: "Cairo";
//     font-family: var(--bs-font-sans-serif);
//     font-style: normal;
//     font-weight: 400;
//     line-height: 1.5;
//     text-align: left;
//     text-align: start;
//     text-decoration: none;
//     text-shadow: none;
//     text-transform: none;
//     letter-spacing: normal;
//     word-break: normal;
//     word-spacing: normal;
//     white-space: normal;
//     line-break: auto;
//     font-size: 0.7109375rem;
//     word-wrap: break-word;
//     background-color: #fff;
//     background-clip: padding-box;
//     border: 1px solid #f6f6f6;
//     border-radius: 0.4rem;
// }
// a.fc-event, a.fc-event:hover {
//   text-decoration: none;
//   max-width: 276px;
//   font-family: "Poppins", sans-serif;
//   font-family: var(--bs-font-sans-serif);
//   font-style: normal;
//   font-weight: 400;
//   line-height: 1.5;
//   text-align: left;
//   text-align: start;
//   text-decoration: none;
//   text-shadow: none;
//   text-transform: none;
//   letter-spacing: normal;
//   word-break: normal;
//   word-spacing: normal;
//   white-space: normal;
//   line-break: auto;
//   font-size: 0.7109375rem;
//   word-wrap: break-word;
//   background-color: #fff;
//   background-clip: padding-box;
//   border: 1px solid #f6f6f6;
//   border-radius: 0.4rem;
// }
.survey-btn .btn {
  height:35px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  /* border-radius: 0.25rem; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.actionplannercircleprogress svg {
  width: 65% !important;
}
.listcircleprogress svg{
  width: 75% !important;
}

.card-body .nav-pills.tab-content {
  border: 1px solid #fff !important; 
  border-top: 0px solid #fff;
}
.card-heightwhitespace{
  white-space: nowrap !important;
  height: 85% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: normal;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
 
}
.titledescpadding{
  padding: 0 20px;

}
.titlepadding{
  padding: 10px 20px;

}
.chooseusercard{
  margin-top: 25px !important;
  padding-bottom: 20px;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.paddingTopCard{
  padding-top: 20px;
}
circle-progress {
  --gradient-color: conic-gradient(
    from 0deg,
    red 0%,
    orange 50%,
    green 100%
  );

  --outer-stroke-gradient: conic-gradient(
    from 0deg,
    var(--gradient-color) var(--progress),
    transparent var(--progress),
    transparent 100%
  );

  --progress: 0;

  [outerStrokeColor] {
    stroke: var(--outer-stroke-gradient);
  }
}
.editalign{
  cursor: pointer;
    position: absolute;
    right: 0px;
    top: -18px;
    width: 10%;
    text-align: right;
}
.editalign1{
  cursor: pointer;
    position: absolute;
    left: 0px;
    top: -18px;
    width: 10%;
    text-align: right;
}
.accordion-attraction .card .icmsheader.table {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png) no-repeat calc(18% + 25px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 16px;
}

[dir="rtl"] .accordion-attraction .card .icmsheader.table {
  background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png) no-repeat calc(82% + 25px) center;
  background-size: 20px;
  cursor: pointer;
  font-size: 16px;
}

[dir="rtl"] .nav-padding-0 {
 padding-right: 0 !important;
}
.dashed-dot-border{
  border-right: 1px dashed #bcbdc8;
  border-left: none;
}

[dir="rtl"] .dashed-dot-border{
  border-left: 1px dashed #bcbdc8;
  border-right: none;
}

[dir="rtl"] .hoverMTop:after{  
  left:inherit !important;
  right: 0; 
}

.employee-popup-div{
  margin-left: 20px;   
  margin-right: 0px;
  padding: 20px;
}
[dir="rtl"] .employee-popup-div{  
  margin-right: 20px; padding: 20px;  
  margin-left: 0px;
}
.modal-header .btn-close {
  background: transparent url("assets/images/icons/cross_icon.png") center/1em auto no-repeat;
}

.align-left-right{
  padding-left: 0px !important;
}
[dir="rtl"] .align-left-right{
  padding-right: 0px !important;
}

//Naseba Style
.nocommentsdiv{
  text-align: center;
  padding: 30px;
  height: 350px;
  overflow-y: auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.nocommentstext{
  color: #ccc;
}

.neat-no-data-container {
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 20px;
  height:300px;
 
}

.neat-no-data-card {
  width:100%;
  text-align: center;
  background-color: #f0f0f0;
  border: none;
  border-radius: 8px;
  height:100%;
  // box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.neat-no-data-card .card-title {
  color: #ccc;
  margin-top: 10px;
  font-size: 1.5rem;
}

.neat-no-data-card .card-text {
  color: #ccc;
  margin-bottom: 20px;
}

.neat-no-data-card i {
  color: #ffc107; 
}

.main-content {background: #f9fafd !important;}
.navs-carousel .owl-nav {margin-top: 0px !important;}

.fc .fc-scrollgrid-liquid {
  height: 360px !important;
}
 .fc .fc-view-harness-active>.fc-view {
  margin-top:0px !important;
}

[dir="rtl"] .thankyou-img-text {
  width: 30%;
  margin-left: 0px;
  margin-right: 20px;
}
.product-image {
  height: 200px;
}
[dir="rtl"] .borderleft {
  border-left: inherit !important;
  padding-left: inherit !important;
  padding-right: 20px;
  border-right: 1px solid #b5b7b994;
}
[dir="rtl"] .activestyle {
  width: 85%;
  margin: auto;
  direction: ltr;
}
.swal2-styled.swal2-confirm {
  background-color: var(--primary-color) !important;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: unset !important;
}

.borderleftstyle{border-left:1px dashed #ccc;}
[dir="rtl"] .borderleftstyle{border-right:1px dashed #ccc;border-left: inherit !important;}


//Pagination Styles
.ngx-pagination a,
.ngx-pagination button {
	cursor: pointer;
	color: var(--primary-color) !important;
	border-radius: 10px ! important;
}

.ngx-pagination a,
.ngx-pagination button {
	color: #0a0a0a;
	display: block;
	padding: 8px 15px !important;
	border-radius: 0 !important;
	font-weight: 600;
}
.ngx-pagination a:hover,
.ngx-pagination button:hover {
	background: var(--primary-color) ! important;
	color: #fff !important;
	padding: 8px 15px;
	border-radius: 0 !important;
}

.ngx-pagination .current {
	border-radius: 0px !important;
	background: var(--primary-color) ! important;
	padding: 8px 15px !important;
	text-align: center;
	font-weight: 600;
}

// .ngx-pagination .pagination-previous,
// .ngx-pagination .pagination-next {
// 	display: none !important;
// }

.ngx-pagination .disabled {
  padding: 8px 15px !important;
  color: #cacaca;
  cursor: default;
}

.ngx-pagination li {
	display: inline-block;
	border: 1px solid #BEBEBE80 !important;
	margin-right: 12px !important;
}

.ngx-pagination {
  margin-left: 0;
  margin-bottom: 0px !important;
}
[dir="rtl"] .formcheck .form-check-input {
  float: right;
  margin-right: -1.5em;
}
[dir="rtl"] .formcheck {
  position: relative;
  text-align: right;
  display: block;
  min-height: 1.21875rem;
  padding-right: 1.5em;
  padding-left: 0;
  margin-bottom: 0.125rem;
}
[dir="rtl"] .formcheck .form-check-label {
  cursor: pointer;
  margin-bottom: 0;
  padding-right: 5px;
}

.tabbed {
  width: 100%;
  min-width: 400px;
  margin: 0 auto;
  margin-bottom: 25px;
  border-bottom: 4px solid var(--primary-color);
  overflow: hidden;
  transition: border 250ms ease;
  height: 38px !important;
  display: flex;
}
.tabbed ul {
  margin: 0px;
  padding: 0px;
  overflow: hidden;
  float: left;
  padding-left: 48px;
  list-style-type: none;
}
.tabbed ul * {
  margin: 0px;
  padding: 0px;
}
.tabbed ul li {
  display: block;
  float: left;
  padding: 10px 24px 8px;
  background-color: #FFF;
  margin-right: 46px;
  z-index: 2;
  position: relative;
  cursor: pointer;
  color: #777;
  text-transform: uppercase;
  font: 700 13px/20px Cairo;
  transition: all 250ms ease;
}
.tabbed ul li:before,
.tabbed ul li:after {
  display: block;
  content: " ";
  position: absolute;
  top: 0;
  height: 100%;
  width: 44px;  
  background-color: #FFF;
  transition: all 250ms ease;
}
.tabbed ul li:before {
  right: -24px;
  transform: skew(30deg, 0deg);
  box-shadow: rgba(0,0,0,.1) 3px 2px 5px, inset rgba(255,255,255,.09) -1px 0;
}
.tabbed ul li:after {
  left: -24px;
  transform: skew(-30deg, 0deg);
  box-shadow: rgba(0,0,0,.1) -3px 2px 5px, inset rgba(255,255,255,.09) 1px 0;
}
.tabbed ul li:hover,
.tabbed ul li:hover:before,
.tabbed ul li:hover:after {
  background-color: #F4F7F9;
  color: #444;  
}
.tabbed ul li.active {
  z-index: 3;
}
.tabbed ul li.active,
.tabbed ul li.active:before,
.tabbed ul li.active:after {
  background-color: var(--primary-color);
  color: #fff;  
}
.tabbed ul {
  display: flex;
  flex-direction: row;   
}
.tabbed ul.rtl {
  flex-direction: row-reverse; 
}
 html[dir="rtl"] ngb-accordion .form-check .form-check-input {
  float: right !important;
  margin-left: 1.5em !important;
}
 html[dir="rtl"]  ngb-accordion .form-check {
  position: relative;
  text-align: right !important;
}
.mlminus-10{
  margin-left: -10px;
}
.w-1265{
  width: 1265px !important;
}
.form-check-input:checked {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.pt10 .progress-bar,.borderbottom .progress-bar {
  background-color: var(--primary-color) !important;
}
.nav-pills .nav-link.active{
  background-color: var(--primary-color) !important;
}
.ck.ck-toolbar {
  background-color: var(--secondary-color) !important;
  border: 1px solid var(--secondary-color) !important; 
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary-color) !important;
  border-color:  var(--primary-color) !important;
}
.btn-primary {
  color: #fff;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}
.alert-info {
  color: var(--primary-color) !important;
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
}
.justify-content-around{
  justify-content: space-around;
}
.justify-content-evenly{
  justify-content: space-evenly;
}
.textcolorwhite{
  color: #fff !important;
}

.mr-3{
  margin-right: 1.5rem;
}
.positive-comments p{ 
  padding-top:0px;
  padding-bottom:15px;
  border-bottom:1px dashed var(--primary-color); 
  margin-bottom:20px;
  line-height: 22px;
  box-shadow: 0px 12px 10px -15px var(--primary-color) !important;
}
.text-right{
  text-align: right !important;
}
.text-left{
  text-align: left !important;
}

html[dir="rtl"]  .bot-icon{
  float: left;
  padding-left: 8px;
} 
.p-0{
  padding: 0;
}

::ng-deep .ck-editor__top{
  border: 1px solid #ccc !important;
}
::ng-deep .ck-editor__main{
  border: 1px solid #ccc !important;
}

[dir=rtl] .vertical-collpsed .main-content {
  margin-right: 70px;
  margin-left: inherit;
}

[dir=rtl] .vertical-collpsed .footer {
  left: 0px !important;
  right: 70px !important;
}

 body {
  overflow-x: hidden;
}

[dir="rtl"] .bottomdata {
  right: 15px !important;
  left: 0px !important;
}