// 
// timeline.scss
//

/************** Horizontal timeline **************/ 


.hori-timeline{
    .events{
        .event-list{
            text-align: center;
            display: block;

            .event-down-icon{
                position: relative;
                &::before{
                    content: "";
                    position: absolute;
                    width: 100%;
                    top: 16px;
                    left: 0;
                    right: 0;
                    border-bottom: 3px dashed $gray-300;
                }
                .down-arrow-icon{
                    position: relative;
                    background-color: $card-bg;
                    padding: 4px;
                }
            }

            &:hover{
                .down-arrow-icon{
                    animation: fade-down 1.5s infinite linear;
                }
            }

            &.active{
                .down-arrow-icon{
                    animation: fade-down 1.5s infinite linear;
                    &:before {
                        content: "\ec4c";
                    }
                }
            }
        }
    }
}


/************** vertical timeline **************/ 

.verti-timeline{
    border-left: 3px dashed $gray-300;
    margin: 0 10px;
    .event-list{
        position: relative;
        padding: 0px 0px 40px 30px;

        .event-timeline-dot{
            position: absolute;
            left: -9px;
            top: 0px;
            z-index: 9;
            font-size: 16px;
        }
        .event-content{
            position: relative;
            border: 2px solid $border-color;
            border-radius: 7px;
        }

        &.active{
            .event-timeline-dot{
                color: var(--primary-color);
            }
        }

        &:last-child{
            padding-bottom: 0px;
        }
    }
}